<template>
  <div class="productList" ref="container">
    <div class="nav acea-row row-middle">
      <div class="item" @click="set_where(1)">
        时间
        <img src="@assets/images/horn.png" v-if="sort === 0" />
        <img src="@assets/images/up.png" v-if="sort === 'asc'" />
        <img src="@assets/images/down.png" v-if="sort === 'desc'" />
      </div>
      <div class="item" @click="set_where(2)">
        热度
        <img src="@assets/images/horn.png" v-if="visit_number === 0" />
        <img src="@assets/images/up.png" v-if="visit_number === 'asc'" />
        <img src="@assets/images/down.png" v-if="visit_number === 'desc'" />
      </div>
    </div>

    <div v-if="lessonList.length > 0">
      <div class="scroll-container">
        <ul class="lesson-list">
          <li class="list-box" v-for="(item, index) in lessonList" :key="index">
            <router-link
              :to="{
                path: `/lesson/onlineDetail/${item.id}`,
              }"
            >
              <div class="img-box">
                <div class="icon">                  
                  <img v-if="item.ico_name == 'ico-new'" src="@assets/images/icon-new.png">
                  <img v-else-if="item.ico_name == 'ico-hot'" src="@assets/images/icon-hot.png">
                  <img v-else-if="item.ico_name == 'ico-vip'" src="@assets/images/icon-vip.png">
                </div>
                <img v-lazy="item.images" />
              </div>
              <div class="text-box">
                <div>
                  <h6>{{ item.bookname }}</h6>
                  <span>{{ item.teacher_name }}</span>
                </div>
                <div>
                  <em>观看数:{{ item.num }}</em>
                  <time class="fl">{{ item.updatetime }}</time>
                  <i class="gold-num fr">{{ parseInt(item.price) > 0 ? '￥' + item.price  : '免费' }}</i>
                </div>
              </div>
              <div class="more" v-if="item.tab == 4">
                查看更多
                <i class="el-icon-arrow-right"></i>
              </div>
            </router-link>
          </li>
        </ul>
        <Loading :loadend="loadend" :loading="loading"></Loading>
      </div>
    </div>
    <div v-else class="emptyData"  v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无数据</p>
    </div>

  </div>
</template>

<script>
import { lessonList } from "@api/public";
import Loading from "@components/Loading";
export default {
  name: "lesson-list",
  components: {
    Loading
  },
  data() {
    return {
      lessonList: [],
      sort: 0,
      visit_number: 0,
      page: 1,
      limit: 10,
      pageSize: 10,
      flag: true,
      loading: false,
      loadend: false,
      initLoading: false
    };
  },
  mounted() {
    window.location.href = "/testh5/pages/index/index"
    this.loadMore();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.loadMore();
    });
  },
  methods: {          
    // 获取列表数据
    loadMore() {
      let that = this;

      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      
      let params = {
        id: that.$route.params.id,
        page: that.page,
        sort: that.sort,
        hot: that.visit_number
      };
      lessonList(params).then(res => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.lessonList.push.apply(that.lessonList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
        that.initLoading = true;
      })
      .catch(res => {
        this.$dialog.toast({ mes: res.msg });
      });
    },
    //点击事件处理
    set_where: function(index) {
      let that = this;
      switch (index) {
        case 0:
          that.$router.go(0);
          break;
        case 1:
          if (that.sort === 0) that.sort = "asc";
          else if (that.sort === "asc") that.sort = "desc";
          else if (that.sort === "desc") that.sort = 0;
          that.visit_number = 0;
          break;
        case 2:
          if (that.visit_number === 0) that.visit_number = "asc";
          else if (that.visit_number === "asc") that.visit_number = "desc";
          else if (that.visit_number === "desc") that.visit_number = 0;
          that.sort = 0;
          break;
        default:
          break;
      }
      that.$set(that, "lessonList", []);
      that.page = 1;
      that.isLoading = false;
      that.loadMore();
    }
    
  }
};
</script>

<style lang="scss" scoped>
.productList {
  padding-bottom: 1rem;
  .scroll-container {
    position: relative;
    padding-top: 1.86rem;
    width: 100%;
    height: auto;
    .lesson-list {
      overflow: hidden;
      width: 7.5rem;
      height: 100%;
      position: relative;
      border-top: 0.02rem solid #fafafa;
      .list-box {
        position: relative;
        padding: 0.2rem 0.3rem;
        margin-bottom: 0.16rem;
        background: white;
      }
      .img-box {
        width: 3.2rem;
        height: 2rem;
        position: relative;
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          width: .8rem;
          height: .8rem;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 0.06rem;
        }
      }
      .text-box {
        position: absolute;
        top: 0.2rem;
        right: 0.3rem;
        width: 3.7rem;
        padding-left: 0.16rem;
        height: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 0.24rem;
        h6 {
          font-size: .26rem;
          margin-bottom: 0.05rem;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        em {
          margin-bottom: 0.05rem;
          font-size: .26rem;
          display: block;
        }
        time, i {
          font-size: .26rem;
          line-height: .3rem;
        }
      }
    }
  }
  .emptyData {
    padding-top: 1.86rem;
  }
}
</style>
